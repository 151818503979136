import styles from './ViewTag.module.css'
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import config from '../../config';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CircularProgress from '@mui/material/CircularProgress';

const ViewTag = (props) => {

    const [tagId, setTagId] = useState(props.id)
    const [name, setName] = useState(props.name)
    const [tag, setTag] = useState('')
    const [loading, setLoading] = useState(true);
    const tagText = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/publishers/${name}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            });
            if (res.status === 403) {
                navigate("/");
            }
            const data = await res.json();
            if (data) {
                setLoading(false);
                if (data.name === 'peak') {
                    const link = config.tagLink.replace('{ID}', tagId).replace('{TRACKING}', data.tracking).replace('{SIGNATURE}', `"${process.env.REACT_APP_API_SIGNATURE}"`);
                    setTag(link)
                } else if (data.name === 'lqm') {
                    const link = config.tagLink.replace('{ID}', tagId).replace('{TRACKING}', data.tracking).replace('{SIGNATURE}', `${process.env.REACT_APP_API_SIGNATURE}`);
                    setTag(link)
                } else {
                    const link = config.tagLinkClean.replace('{ID}', tagId);
                    setTag(link)
                }
            }
        }
        )()


    }, [tag.setTag, tagId, name, setTagId, setName, setLoading, navigate])

    const copyLink = () => {
        navigator.clipboard.writeText(tagText.current.innerText);
        props.onClose();
    }

    const backToLayOut = (e) => {
        props.onClose();
    }

    return (


        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            {loading ? <CircularProgress /> :
                <Card sx={{ maxWidth: 520, maxHeight: 500, borderRadius: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <CardHeader
                        action={
                            <IconButton sx={{ position: 'relative', left: '250%' }} onClick={backToLayOut}>
                                <CloseIcon />
                            </IconButton>
                        }
                        title='Tag'
                        sx={{ color: '#289FD2' }}
                    />
                    <CardContent>
                        <div ref={tagText} className={styles.textMargin} type='text' >{tag}</div>
                    </CardContent>
                    <CardActions sx={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                        <button className={styles.copyButton} onClick={copyLink}>Copy</button>
                    </CardActions>
                </Card>
            }
        </Backdrop>

    )

}

export default ViewTag;